<template>
  <span>
    <v-app-bar
      elevate-on-scroll
      absolute
      class="nav-slide-y"
      app
      color="darkGrey"
      dark
      height="50px"
    >
      <v-btn
        @click="$router.push('/campaign-requests')"
        v-if="$route.path.indexOf('manage') > -1"
        color="white"
        icon
        ><v-icon>{{ mdiArrowLeft }}</v-icon></v-btn
      >
      <v-btn v-else-if="$route.path.indexOf('user') > -1" @click="$router.go(-1)"  color="white" icon
        ><v-icon>{{ mdiChevronLeft }}</v-icon></v-btn
      >
      <v-btn @click="$router.push('/my-campaigns')" v-else color="white" icon
        ><v-icon>{{ mdiHome }}</v-icon></v-btn
      >

      <!-- <v-app-bar-nav-icon @click="drawer = !drawer" v-else></v-app-bar-nav-icon> -->
      <v-toolbar-title class="text-xs-right">{{
        $route.meta.nav ? $route.meta.nav.name : ""
      }}</v-toolbar-title>
      <!-- <div class="btn" @click="extended = !extended">
        <v-btn small fab color="darkGrey"
          ><v-icon color="red">{{ mdiSync }}</v-icon></v-btn
        >
      </div> -->
      <v-spacer></v-spacer>
      <unoapp-account-selector
        @logoutClicked="logout"
        @unoappBusinessSelected="businessSelected"
        :logoutRedirect="'https://auth.dev.unoapp.io'"
        :auth-token="vg_user.token"
        :first-name="vg_user.first_name"
        :profile-btn="'false'"
      ></unoapp-account-selector>
      <!-- <v-select label="label"></v-select> -->
    </v-app-bar>
    <v-navigation-drawer
      dark
      app
      v-model="drawer"
      :mini-variant="$root.toggleMini"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="../assets/images/uno_x_logo_180.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <img class="responsive-img" src="../assets/images/uno-logo.png" />
        </v-list-item-content>
        <!-- <v-list-item-title>
          <v-select outlined></v-select>
        </v-list-item-title> -->

        <!-- <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn> -->
      </v-list-item>

      <v-list dense>
        <v-list-item
          v-for="(item, index) in clientApplications"
          :key="`${item.name}_${index}`"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.meta.nav.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.meta.nav.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dense>
        <v-subheader v-if="!mini && this.isUserSuperAdmin()">ADMIN</v-subheader>
        <v-list-item
          v-for="(item, index) in adminApplications"
          :key="`${item.name}_${index}`"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.meta.nav.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.meta.nav.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </span>
</template>

<script>
import { mdiArrowLeft, mdiSync, mdiHome, mdiChevronLeft } from '@mdi/js'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      mdiChevronLeft,
      mdiHome,
      mdiSync,
      mdiArrowLeft,
      extended: false,
      drawer: true,
      mini: true
    }
  },
  created() {
  },
  computed: {
    ...mapGetters({
      vg_user: 'auth/user',
      vg_logoutStatus: 'auth/logoutStatus'
    }),
    clientApplications() {
      const allRoutes = this.$router.options.routes
      return allRoutes.filter((route) => {
        return route.meta && route.meta.nav && route.meta.nav.display && route.meta.admin === false
      })
    },
    adminApplications() {
      // if (this.isUserSuperAdmin()) {
      //   const allRoutes = this.$router.options.routes
      //   return allRoutes.filter((route) => {
      //     return route.meta && route.meta.nav && route.meta.nav.display && route.meta.admin
      //   })
      // }
      return []
    }
  },
  methods: {
    ...mapMutations({ vm_setLogoutStatus: 'auth/SET_LOGOUT_STATUS' }),
    async logout(clicked) {
      console.log(clicked)
      this.$router.push('/login')
      if (clicked.detail === true) {
        this.vm_setLogoutStatus(true)
        this.$snackbar({ text: 'Logged out successfully.', y: 'top', x: 'right', color: 'success', icon: 'mdi-check', timeout: 6000 })
      } else if (!this.vg_logoutStatus) {
        this.$snackbar({ text: 'Session Expired. Please login again.', y: 'top', x: 'right', color: 'errorButton', icon: 'mdi-account', timeout: 6000 })
      }
    },
    businessSelected(s) {
      this.$router.push('/')
      this.$root.$emit('business-changed')
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  z-index: 20 !important;
  position: fixed;
  left: 50%;
  bottom: -35px;
  transform: translate(-50%, -50%);
}

.responsive-img {
  width: 100%;
  height: 50px;
}
</style>
